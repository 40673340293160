import { tmLoadModal } from '@uc-tm/modal-loader';
import { transactionTemplateApplied } from '@uc/analytics-definitions';
import capitalize from 'lodash/capitalize';
import pick from 'lodash/pick';
import { applyTemplates } from 'src/models/transactions/intents';
import type PropertyInfo from 'src/models/transactions/items/property-info';
import type Transaction from 'src/models/transactions/transaction';
import appStore from 'src/stores/app-store';
import type EmbeddedAppStore from 'src/stores/embedded-app-store';
import { productPathFromEmbeddedFeature } from 'src/utils/analytics/product-path';

/**
 * Load ApplyTemplatesModal from Compass CDN by modal-loader.
 *
 * @see https://github.com/UrbanCompass/uc-frontend/tree/master/workspaces/tm/packages/modal--apply-templates
 */
export const loadApplyTemplatesModal = async ({
  transaction,
  allowEmptyProperty = true,
  embeddedApp,
}: {
  transaction: Transaction;
  embeddedApp?: EmbeddedAppStore;
  allowEmptyProperty?: boolean;
}) => {
  try {
    await tmLoadModal('tm/apply-templates/0', {
      properties: transaction
        .getProperties()
        .map((property: PropertyInfo) =>
          pick(property, ['id', 'offerPackage.id', 'address.street'])
        ),
      allowEmptyProperty,
      fetchTemplates: async (...args: [string, Record<string, unknown>]) => {
        const templates =
          await appStore.transactionTemplates.getTransactionsData(...args);
        return templates.map((template) => ({
          ...pick(template, ['id', 'title', 'hasDeactivatedForms']),
          showCompassTag: ['TEAMS', 'BROKERAGE'].includes(
            template.meta.templateVisibility
          ),
        }));
      },
      onOk: async ({
        data: { selectedTemplateIds, selectedPropertyIds },
      }: {
        data: {
          selectedTemplateIds: string[];
          selectedPropertyIds: string[];
        };
      }) => {
        const { transactions, ui, transactionTemplates } = appStore;
        // @ts-ignore
        const templateData = await transactionTemplates.getTransactionsData();

        const deactivatedTemplates = templateData.filter(
          (template) =>
            selectedTemplateIds.includes(template.id) &&
            template.hasDeactivatedForms
        );
        const totalDeactivatedForms = deactivatedTemplates.reduce(
          (prev, curr) => prev + parseInt(curr.deactivatedForms, 10),
          0
        );

        try {
          const {
            result: { errors },
          } = await transactions.dispatch(
            transaction.id,
            applyTemplates(selectedTemplateIds, selectedPropertyIds)
          );
          const selectedTemplatesData = templateData.filter((t) =>
            selectedTemplateIds.includes(t.id)
          );
          transactionTemplateApplied({
            product: 'business_tracker',
            sub_product: 'transaction_templates',
            product_path: productPathFromEmbeddedFeature(
              embeddedApp?.embeddedFeature
            ),
            template_type: selectedTemplatesData.map((template) =>
              capitalize(template.templateVisibility)
            ),
          });
          const hasErrors = Boolean(errors?.length);
          ui.toast({
            message: totalDeactivatedForms
              ? `${totalDeactivatedForms} form${
                  totalDeactivatedForms > 1 ? 's were' : ' was'
                } not copied into the transaction because ${
                  totalDeactivatedForms > 1 ? 'they have' : 'it has'
                } been deactivated`
              : 'Successfully applied templates',
            description: hasErrors
              ? 'Some errors ocurred during application of selected templates.'
              : undefined,
            type: hasErrors ? 'error' : 'success',
          });
          return { errors };
        } catch (err) {
          ui.wentWrong(err as any);
        }
      },
      transaction: pick(transaction, [
        'id',
        'side',
        'isLease',
        'isPurchase',
        'address.id',
        'propertyInfo.id',
      ]),
    });
  } catch (err) {
    if (!err) {
      return; // cancel
    }
    console.error('ApplyTemplatesModal error', err);
  }
};
