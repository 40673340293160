import api from 'src/api';
import navigateToFlow from 'src/components/flows/navigate-to-flow';
import type RouterStore from 'src/stores/router-store';
import type UiStore from 'src/stores/ui-store';

const addNewTransaction = async (
  router: RouterStore,
  ui: UiStore,
  options = {}
) => {
  try {
    const { data: flowId } = await api.transactions.flowsTxnCreate(options);
    navigateToFlow(router, flowId);
  } catch (err) {
    if (ui) {
      ui.wentWrong(err as Error);
    }
  }
};
export default addNewTransaction;
