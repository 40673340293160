

import React, { Component } from 'react';
import { Checkbox, Input, Select } from 'antd';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import AppFormModal from 'src/components/forms/app-form-modal';
import { QuickForm } from 'src/components/forms/quick-form';
import { Optional, Required } from 'src/components/forms/quick-form/validation';
import { updateTransactionState } from 'src/models/transactions/intents';
import { CANCELLATION_REASONS } from 'src/models/transactions/transaction';

const { TextArea } = Input;

const clsPrefix = 'app-cancel-transaction-modal';

@inject('ui', 'transactions')
@observer
export default class CancelTransactionModal extends Component {
  static propTypes = {
    ui: PropTypes.object.isRequired,
    transactions: PropTypes.object.isRequired,
    transaction: PropTypes.object.isRequired,
    className: PropTypes.string,
    onSuccess: PropTypes.func.isRequired,
  };

  handleSubmit = async ({ reason, details, force }) => {
    const { ui, transactions, transaction, onSuccess } = this.props;

    const status =
      (transaction.userHasPerm('APPROVE_CANCELLATIONS') && force) ||
      !transaction.org
        ? 'CANCELLED'
        : 'CANCEL_REQUESTED';
    try {
      await transactions.dispatch(
        transaction.id,
        updateTransactionState(status, {
          cancellationMeta: {
            reason,
            details,
          },
          optimistic: false,
        })
      );
      onSuccess();
      ui.toast({
        type: 'success',
        message:
          status === 'CANCELLED'
            ? 'Cancelled transaction'
            : 'Requested cancellation',
      });
    } catch (err) {
      ui.wentWrong(err);
    }
  };

  render() {
    const { transaction, className, ...otherProps } = this.props;
    const modalProps = omit(otherProps, ['ui', 'transactions', 'onSuccess']);

    return (
      <AppFormModal
        className={classNames(clsPrefix, className)}
        title="Cancel Transaction"
        okText="OK"
        {...modalProps}
        onSubmit={this.handleSubmit}
      >
        {({ formRef, onSubmit }) => (
          <QuickForm
            ref={formRef}
            onSave={onSubmit}
            className={`${clsPrefix}__form`}
            name="cancel-transaction"
            initialValue={{
              reason: '',
              details: '',
              force: false,
            }}
            validation={({ reason }) => {
              return {
                reason: Required(),
                details: reason === 'OTHER' ? Required() : Optional(),
              };
            }}
            hideControls
            container="form"
          >
            {() => (
              <React.Fragment>
                <QuickForm.Item
                  name="reason"
                  label="Reason for cancellation"
                  validate="all"
                >
                  <Select
                    placeholder="Select reason"
                    defaultActiveFirstOption={false}
                  >
                    {Object.entries(CANCELLATION_REASONS).map(
                      ([value, label]) => (
                        <Select.Option key={value} value={value}>
                          {label}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </QuickForm.Item>
                <QuickForm.Item name="details" label="Additional details">
                  <TextArea rows={3} />
                </QuickForm.Item>
                {transaction.userHasPerm('APPROVE_CANCELLATIONS') && (
                  <QuickForm.Item name="force" valuePropName="checked">
                    <Checkbox>Approve full cancellation</Checkbox>
                  </QuickForm.Item>
                )}
              </React.Fragment>
            )}
          </QuickForm>
        )}
      </AppFormModal>
    );
  }
}
