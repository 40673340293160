import React from 'react';
import moment from 'moment';
import type PropertyInfo from 'src/models/transactions/items/property-info';
import type TransactionPackage from 'src/models/transactions/items/transaction-package';
import { getColor } from 'src/utils/get-color';

type OfferPrice = {
  formatted: string;
};

type OfferKeyPoints = {
  submittedAt?: string;
  createdAt?: string;
  lastEffectedAt?: string;
};

export type OfferCover = {
  label?: string;
  description?: string;
  price?: React.ReactNode;
  color?: string;
  fontColor?: string;
};

type StatusCoverGetter = (
  offerPackage?: TransactionPackage,
  propertyInfo?: PropertyInfo
) => {
  noCover?: boolean;
} & OfferCover;

type MapStatusToCovers = Record<string, StatusCoverGetter>;

const clsPrefix = 'app-property-cards';

export function getOfferKeyPoints(
  offerPackage?: TransactionPackage
): OfferKeyPoints | null {
  const offer = offerPackage?.offer;

  return offer
    ? {
        createdAt: formatDateTime(offerPackage.createdAt),
        submittedAt: formatDateTime(offer.submitted.fixedTs),
        // This field will record the time when the offer switches from one status to another
        lastEffectedAt: formatDateTime(offer.effectiveFrom),
      }
    : null;
}

export const OfferPriceLabel = (props: {
  offerPrice?: OfferPrice;
  isFinal?: boolean;
}) => {
  const { offerPrice, isFinal = false } = props;

  if (!offerPrice) {
    return null;
  }

  const text = isFinal ? 'Final Offer Price' : 'Offer Price';

  return (
    <div className={`${clsPrefix}__cover--footer`}>
      {text}: {offerPrice.formatted}
    </div>
  );
};

type offerVoidedStatus = 'REJECTED' | 'EXPIRED' | 'VOIDED';
function getVoidOfferCoverGetter(status: offerVoidedStatus): StatusCoverGetter {
  return (offerPackage?: TransactionPackage) => {
    const offerKeyPoints = getOfferKeyPoints(offerPackage);
    const userStatus = status !== 'VOIDED' ? status : 'CANCELLED';
    return {
      label: `OFFER ${userStatus}`,
      color: '#f55667',
      fontColor: getColor('white-color'),
      price: offerPackage?.offer?.price && (
        <OfferPriceLabel offerPrice={offerPackage.offer.price} />
      ),
      description: offerKeyPoints?.lastEffectedAt
        ? `${userStatus}: ${offerKeyPoints.lastEffectedAt}`
        : '',
    };
  };
}

export const mapStatusToCovers: MapStatusToCovers = {
  SUBMITTED: (offerPackage?: TransactionPackage) => {
    const offerKeyPoints = getOfferKeyPoints(offerPackage);
    return {
      label: 'OFFER SUBMITTED',
      color: getColor('yellow-7'),
      fontColor: '#555',
      price: offerPackage?.offer?.price && (
        <OfferPriceLabel offerPrice={offerPackage.offer.price} />
      ),
      description: offerKeyPoints?.submittedAt
        ? `Submitted: ${offerKeyPoints.submittedAt}`
        : '',
    };
  },
  PENDING: (offerPackage?: TransactionPackage) => {
    const offerKeyPoints = getOfferKeyPoints(offerPackage);
    return {
      label: 'OFFER STARTED',
      color: getColor('blue-0'),
      fontColor: '#555',
      description: offerKeyPoints?.createdAt
        ? `Started: ${offerKeyPoints.createdAt}`
        : '',
    };
  },

  COUNTERED: (offerPackage?: TransactionPackage) => {
    const offerKeyPoints = getOfferKeyPoints(offerPackage);
    return {
      label: 'COUNTERED',
      color: '#EA8060',
      fontColor: getColor('white-color'),
      price: offerPackage?.offer?.price && (
        <OfferPriceLabel offerPrice={offerPackage.offer.price} />
      ),
      description: offerKeyPoints?.lastEffectedAt
        ? `Countered: ${offerKeyPoints.lastEffectedAt}`
        : '',
    };
  },
  ACCEPTED: (offerPackage?: TransactionPackage) => {
    const offerKeyPoints = getOfferKeyPoints(offerPackage);
    return {
      label: 'OFFER ACCEPTED',
      color: '#00b293',
      fontColor: getColor('white-color'),
      price: offerPackage?.offer?.price && (
        <OfferPriceLabel isFinal offerPrice={offerPackage.offer.price} />
      ),
      description: offerKeyPoints?.lastEffectedAt
        ? `Accepted: ${offerKeyPoints.lastEffectedAt}`
        : '',
    };
  },
  REJECTED: getVoidOfferCoverGetter('REJECTED'),
  EXPIRED: getVoidOfferCoverGetter('EXPIRED'),
  VOIDED: getVoidOfferCoverGetter('VOIDED'),
};

mapStatusToCovers.PENDING_COUNTER_ACCEPT = mapStatusToCovers.SUBMITTED;
mapStatusToCovers.COUNTER_ACCEPTED = mapStatusToCovers.SUBMITTED;
mapStatusToCovers.PENDING_ACCEPT = mapStatusToCovers.SUBMITTED;
mapStatusToCovers.PENDING_COUNTER = mapStatusToCovers.COUNTERED;
mapStatusToCovers.DRAFT = mapStatusToCovers.PENDING;
mapStatusToCovers.READY_TO_SUBMIT = mapStatusToCovers.PENDING;
mapStatusToCovers.PENDING_SUBMIT = mapStatusToCovers.PENDING;
// When there's no offer object, show accepted status if the property is the primary
mapStatusToCovers.NO_OFFER = (
  offerPackage?: TransactionPackage,
  propertyInfo?: PropertyInfo
) => {
  (window as any).offerPackage = offerPackage;
  return propertyInfo?.isPrimary
    ? mapStatusToCovers.ACCEPTED(offerPackage, propertyInfo)
    : {
        label: '',
        color: '',
        fontColor: '',
        description: '',
        noCover: true,
      };
};

const formatDateTime = (timestamp: string) =>
  moment(new Date(parseInt(timestamp, 10))).format('MMM DD, YYYY [at] h:mm A');

export function getOfferCover(
  offerPackage: TransactionPackage,
  propertyInfo?: PropertyInfo
): OfferCover | null {
  const offerStatus = offerPackage?.offer?.status ?? 'NO_OFFER';
  const cover = mapStatusToCovers[offerStatus]?.(offerPackage, propertyInfo);

  if (cover?.noCover) {
    return null;
  }

  return (
    cover || {
      label: 'OFFER STARTED',
      color: getColor('blue-0'),
      fontColor: '#555',
      description: '',
    }
  );
}
export default getOfferCover;
