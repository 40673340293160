

import React, { type FC, ReactNode } from 'react';
import classNames from 'classnames';
import AppIcon from './app-icon';
import infoIcon from 'src/images/icons/info.svg';

interface Props {
  className?: string;
  type?: keyof typeof _ICONS_BY_TYPE;
  children?: ReactNode;
  showIcon?: boolean;
  iconSize?: number;
  customIcon?: string;
}

const clsPrefix = 'app-alert';

const _ICONS_BY_TYPE = {
  default: 'info-circle',
  success: 'check-circle',
  warning: 'exclamation-circle',
  error: 'close-circle',
  info: 'info-circle',
  warning_strong: 'exclamation-circle',
};

const AppAlert: FC<Props> & { ACTION_BTN_CLS: string } = ({
  className,
  type = 'default',
  children,
  showIcon = true,
  customIcon,
  iconSize,
}) => (
  <div
    className={classNames(clsPrefix, `${clsPrefix}--${type}`, className, {
      [`${clsPrefix}--no-icon`]: !(showIcon || customIcon),
    })}
  >
    {(customIcon || (showIcon && !!_ICONS_BY_TYPE[type])) && (
      <AppIcon
        className={`${clsPrefix}__icon`}
        type={type === 'info' ? 'svg' : 'antd'}
        svg={type === 'info' ? infoIcon : ''}
        size={iconSize}
        name={customIcon || _ICONS_BY_TYPE[type]}
      />
    )}
    <div className={`${clsPrefix}-content`}>{children}</div>
  </div>
);

AppAlert.ACTION_BTN_CLS = `${clsPrefix}__action-btn`;

export default AppAlert;
