import AppModal from 'src/components/common/app-modal';
import PromiseButton from 'src/components/common/promise-button';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import chaining from 'src/utils/chaining';
import { inject, observer } from 'mobx-react';

@inject('ui')
@observer
export default class AppFormModal extends Component {
  static Footer = AppModal.Footer;

  static propTypes = {
    formRef: PropTypes.func,
    renderForm: PropTypes.func,
    children: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    okLoading: PropTypes.bool,
    showAddAnother: PropTypes.bool,
    addAnotherText: PropTypes.string,
    ui: PropTypes.object.isRequired,
  };

  static defaultProps = {
    onSuccess: () => {},
    addAnotherText: 'Save & add another',
  };

  state = {
    okLoading: false,
    addAnotherLoading: false,
  };

  addAnother = false;

  setFormRef = (formRef) => {
    this.formRef = formRef;
  };

  onOk = () => {
    if (this.formRef) {
      this.formRef.submit();
    }
  };

  onAddAnotherClick = () => {
    this.addAnother = true;
    if (this.formRef) {
      this.formRef.submit();
    }
  };

  onSubmittingChange = (submitting) => {
    this.setState(
      !this.addAnother
        ? {
            okLoading: submitting,
          }
        : {
            addAnotherLoading: submitting,
          }
    );
  };

  onSubmit = async (...args) => {
    const loadingProps = !this.addAnother ? 'okLoading' : 'addAnotherLoading';
    try {
      this.setState({
        [loadingProps]: true,
      });
      const res = await this.props.onSubmit(...args, {
        addAnother: this.addAnother,
      });
      if (res !== undefined && res !== null) {
        this.props.onSuccess(res);
      }
    } finally {
      this.setState({
        [loadingProps]: false,
      });
      this.addAnother = false;
    }
  };

  onChange = () => {
    this.valuesChanged = true;
  };

  cancel = (...args) => {
    const { onCancel, ui } = this.props;
    if (onCancel && ui.isGlideMobileApp) {
      onCancel(...args);
      return;
    }
    if (
      onCancel &&
      (!this.valuesChanged ||
        confirm(
          'Are you sure you want to navigate away? You have unsaved changes.'
        ))
    ) {
      onCancel(...args);
    }
  };

  cancelFormEditForChangePrimaryAgent = () => {
    return !this.valuesChanged ||
      confirm(
        'Are you sure you want to navigate away? You have unsaved changes.'
      )
  };

  render() {
    const {
      formRef,
      renderForm,
      children,
      onOk,
      showAddAnother,
      addAnotherText,
      ...modalProps
    } = this.props;
    const { addAnotherLoading } = this.state;
    return (
      <AppModal
        {...modalProps}
        onOk={chaining(this.onOk, onOk)}
        okButtonProps={{
          ...modalProps.okButtonProps,
          loading: this.state.okLoading || this.props.okLoading,
        }}
        onCancel={this.cancel}
        footer={
          showAddAnother
            ? ({ cancelBtn, okBtn, ...otherProps }) => (
                <AppModal.Footer
                  {...otherProps}
                  btns={[
                    cancelBtn,
                    <PromiseButton
                      key="okAndAddAnother"
                      onClick={this.onAddAnotherClick}
                      loading={addAnotherLoading}
                    >
                      {addAnotherText}
                    </PromiseButton>,
                    okBtn,
                  ]}
                />
              )
            : undefined
        }
      >
        {(renderForm || children)({
          formRef: chaining(this.setFormRef, formRef),
          onSubmit: this.onSubmit,
          onSubmittingChange: this.onSubmittingChange,
          onChange: this.onChange,
          cancelFormEditForChangePrimaryAgent: this.cancelFormEditForChangePrimaryAgent,
        })}
      </AppModal>
    );
  }
}
